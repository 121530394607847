import React, {Component} from "react"
import { BrowserView, MobileView } from "react-device-detect"

import { withStyles, Box, Button, Typography, Paper } from "@material-ui/core"

const style = theme => ({
  rootB: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paperBLeft: {
    display: "flex",
    width: "45%",
    height: "80%",
    margin: "auto",
    justifyContent: "center",
    alignItems: "space-between",
    flexDirection: "column",
    animation: `$slowShowLeft 1200ms ${theme.transitions.easing.easeInOut}`
  },
  paperBRight: {
    display: "flex",
    width: "45%",
    height: "80%",
    margin: "auto",
    justifyContent: "center",
    alignItems: "space-between",
    flexDirection: "column",
    animation: `$slowShowRight 1200ms ${theme.transitions.easing.easeInOut}`
  },
  imageB: {
    maxWidth: "70%",
    maxHeight: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
  },
  socialsBoxB: {
    width: "80%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    border: "2px solid #00000020",
    borderRadius: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  socialButtonB: {
    color: "#000000",
    width: "80%",
    border: "1px solid",
  },
  rootM: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paperM: {
    display: "flex",
    width: "80%",
    height: "45%",
    margin: "auto",
    justifyContent: "center",
    alignItems: "space-between",
    flexDirection: "column",
    animation: `$slowShowMobile 800ms ${theme.transitions.easing.easeInOut}`
  },
  imageM: {
    width: "50%",
    height: "50%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  socialsBoxM: {
    width: "80%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    border: "2px solid #00000020",
    borderRadius: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  socialButtonM: {
    color: "#000000",
    width: "80%",
    border: "1px solid",
  },
  "@keyframes slowShowLeft": {
    "0%": {
      transform: "translateX(-150%)",
    },
    "100%": {
      transform: "translateX(0%)",
    }
  },
  "@keyframes slowShowRight": {
    "0%": {
      transform: "translateX(150%)",
    },
    "100%": {
      transform: "translateX(0%)",
    }
  },
  "@keyframes slowShowMobile": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
})

class MainComponent extends Component {
  constructor(props) {
    super(props)

    this.onClickYoutubeRLGL = this.onClickYoutubeRLGL.bind(this)
    this.onClickSoundcloudRLGL = this.onClickSoundcloudRLGL.bind(this)
    this.onClickYoutubeMashup = this.onClickYoutubeMashup.bind(this)
  }

  onClickYoutubeRLGL() {
    window.open('https://www.youtube.com/watch?v=hFiYRlM9j7E', '_blank', 'noreferrer');
  }

  onClickSoundcloudRLGL() {
    window.open('https://soundcloud.com/cuerage/cuerage-x-tesflour-red-light-green-light', '_blank', 'noreferrer');
  }

  onClickYoutubeMashup() {
    window.open('https://youtu.be/f_FHwA_ZQ48', '_blank', 'noreferrer')
  }

  render() {
    const {classes} = this.props;

    return(
      <div>
        <BrowserView className={classes.rootB}>
          <Paper elevation={3} className={classes.paperBLeft}>
            <img src={"/img/coverRLGL.png"} alt={"Red Light Green Light Logo"} className={classes.imageB} />
            <Box className={classes.socialsBoxB}> 
              <Button className={classes.socialButtonB} onClick={this.onClickYoutubeRLGL} key="youtube" id="youtube" variant="outlined" color="secondary"><Typography variant="h5">Youtube</Typography></Button>
              <Button className={classes.socialButtonB} onClick={this.onClickSoundcloudRLGL} key="soundcloud" id="soundcloud" variant="outlined" color="secondary"><Typography variant="h5">SoundCloud</Typography></Button>
            </Box>
          </Paper>
          <Paper elevation={3} className={classes.paperBRight}>
            <img src={"/img/coverMashup.png"} alt={"Put It On Me In The Hotel Room Logo"} className={classes.imageB} />
            <Box className={classes.socialsBoxB}> 
              <Button className={classes.socialButtonB} onClick={this.onClickYoutubeMashup} key="youtube" id="youtube" variant="outlined" color="secondary"><Typography variant="h5">Youtube</Typography></Button>
            </Box>
          </Paper>
        </BrowserView>
        <MobileView className={classes.rootM}>
          <Paper elevation={3} className={classes.paperM}>
            <img src={"/img/coverRLGL.png"} alt={"Red Light Green Light Logo"} className={classes.imageM} />
            <Box className={classes.socialsBoxM}> 
              <Button className={classes.socialButtonM} onClick={this.onClickYoutubeRLGL} key="youtube" id="youtube" variant="outlined" color="secondary"><Typography variant="h5">Youtube</Typography></Button>
              <Button className={classes.socialButtonM} onClick={this.onClickSoundcloudRLGL} key="soundcloud" id="soundcloud" variant="outlined" color="secondary"><Typography variant="h5">SoundCloud</Typography></Button>
            </Box>
          </Paper>
          <Paper elevation={3} className={classes.paperM}>
            <img src={"/img/coverMashup.png"} alt={"Put It On Me In The Hotel Room Logo"} className={classes.imageM} />
            <Box className={classes.socialsBoxM}> 
              <Button className={classes.socialButtonM} onClick={this.onClickYoutubeMashup} key="youtube" id="youtube" variant="outlined" color="secondary"><Typography variant="h5">Youtube</Typography></Button>
            </Box>
          </Paper>
        </MobileView>
      </div>
    )
  }
}

export default withStyles(style)(MainComponent)